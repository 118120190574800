import {  KeyboardEvent, useEffect, useRef } from 'react';

interface IValidInputProps {
  callback: any,
  index: number,
  value: string,
  isFocused: boolean,
  isValid: boolean,
}

const ValidInput = (props: IValidInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.isFocused) {
      inputRef?.current?.focus();
    }
  }, [props.isFocused]);

  const handleUpdate = (event: KeyboardEvent<HTMLInputElement>) => {
    props.callback(props.index, event.key);
  };

  const handleOnChange = () => {}

  const validStyle = 'border-green-500 text-green-500';
  const invalidStyle = 'border-red-500 opacity-20 text-red-500';

  return (
    <input
      className={`${props.isValid ? validStyle : invalidStyle} border-b-2 border-solid inline-block text-4xl lg:text-8xl text-center w-10 lg:w-20 uppercase`}
      value={props.value}
      onKeyDown={handleUpdate}
      onChange={handleOnChange}
      ref={inputRef}
      maxLength={1}
    ></input>
  );
};
export default ValidInput;
