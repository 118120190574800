import { useNavigate } from "react-router-dom";
import { EXPLORE_PATH } from "../../App";

const FlashCardStageCompletedDialog = () => {
  const navigate = useNavigate();

  const redirectToExplorerPage = () => {
    navigate(EXPLORE_PATH)
  }

  return (
    <div onClick={redirectToExplorerPage} style={{
      background: '#4ade80',
      border: '1px solid #999',
      borderRadius: '0.375rem',
      color: 'white',
      cursor: 'pointer',
      padding: '50px 50px',
    }}>
      <span style={{ fontSize: '22px', fontWeight: '800', }}>Congratulation, stage completed!</span><br />
      <span>Click to return to Explorer page</span>
    </div>
  );
}

export default FlashCardStageCompletedDialog;
