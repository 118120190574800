import { useState } from 'react';
import { ILetter } from '../../models/IFlashCardData';
import ValidInput from './valid_input';

interface IFlashInputProps {
  word: string,
  letters: Array<ILetter>,
  callbackNextCard: any,
}

const FlashInput = (props: IFlashInputProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Empty user Input with same size as wordData
  const [userInput, setUserInput] = useState(
    props.letters.map(() => {
      return '';
    })
  );

  const isAllMatch = () => {
    for (var i = 0; i < props.letters.length; i++) {
      if (props.letters[i].char.toUpperCase() !== userInput[i].toUpperCase()) {
        return false;
      }
    }
    return true;
  };

  const handleCallback = (index: number, key: string) => {
    if (key === 'Backspace') {
      setCurrentIndex(index - 1);
      updateUserInput(index, '');
    }
    if (key === 'Enter' && isAllMatch()) {
      props.callbackNextCard();
    }
    if (key.length > 1) {
      // Ignore non-character input
      return;
    }
    updateUserInput(index, key);
    if (key.toUpperCase() === props.letters[index].char.toUpperCase()) {
      setCurrentIndex(index + 1);
    }
  };

  const updateUserInput = (index: number, key: string) => {
    let updatedUserInput = { ...userInput };
    updatedUserInput[index] = key;
    setUserInput(updatedUserInput);
  };

  return (
    <div className="flex gap-4 justify-center">
      {props.letters.map((data: ILetter, index) => {
        return (
          <ValidInput
            key={index}
            index={index}
            value={userInput[index] ?? ''}
            callback={handleCallback}
            isFocused={currentIndex === index}
            isValid={userInput[index]?.toUpperCase() === data.char.toUpperCase()}
          />
        );
      })}
    </div>
  );
};
export default FlashInput;
