import { useEffect, useState } from 'react';
import ExplorerMap from '../../components/ExplorerMap/ExplorerMap';
import ExplorerMapData from '../../components/ExplorerMap/models/ExplorerMapData';
import ApplicationLayout from '../../layouts/application_layout';
import { ExplorerMapRequestLocal } from '../../services/ExplorerMapRequestLocal';

export const ExplorePage = (props: any) => {
  const [flashCardStages, setFlashCardStages] = useState<ExplorerMapData[]>();
  useEffect(() => {
    const updateStages = async () => {
      setFlashCardStages(await new ExplorerMapRequestLocal().getAreas());
    };
    updateStages();
  }, []);

  // TODO get levelsCompleted from server
  const levelsCompleted = ['zoo1', 'zoo2', 'zoo3', 'house1', 'house2', 'z1'];
  return (
    <ApplicationLayout>
      <div>
        {flashCardStages !== undefined ? <ExplorerMap explorerMapData={flashCardStages} levelsCompleted={levelsCompleted} /> : <></>}
      </div>
    </ApplicationLayout>
  );
};

export default ExplorePage;
