import TopMenu from '../components/top_menu';

const ApplicationLayout = (props: any) => {
  return (
    <div className="flex flex-col">
      <TopMenu />
      <div className="flex justify-center">{props.children}</div>
    </div>
  );
};
export default ApplicationLayout;
