import { Link } from 'react-router-dom';
import ExplorerMapData from './models/ExplorerMapData';

interface ExplorerMapStageProps {
  explorerMapData: ExplorerMapData;
  currentLevel: number;
}

const ExplorerMapStage = (props: ExplorerMapStageProps) => {
  return (
    <div>
      <div className="text-center mb-10">
        <h1 className="text-3xl">{props.explorerMapData.title}</h1>
      </div>
      <div className="">
        <div className="h-80 align-bottom">
          <Link key={props.currentLevel} to={`/flash-card/${props.explorerMapData.levels[props.currentLevel]}`}>
            <img className="w-96" alt={`${props.explorerMapData.title} stage`} src={`${props.explorerMapData.imageUrl}`} />
          </Link>
        </div>
        {/* <FlashCardStageProgressIndicator totalStages={props.totalLevels} totalStagesCompleted={props.totalLevelsCompleted} /> */}
      </div>
    </div>
  );
};

export default ExplorerMapStage;
