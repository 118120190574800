import FlashCardData from '../components/FlashCard/models/FlashCardData';
import FlashCardRequest from '../pages/FlashCardPage/services/FlashCardRequest';

export class FlashCardRequestRemote implements FlashCardRequest {
  async getCard(stage: string, id: string): Promise<FlashCardData> {
    let response = await fetch(`https://${process.env.REACT_APP_API_HOST}/flashcard/${stage}/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    let item = (await response.json()) as FlashCardData;
    return item;
  }

  async getDataRange(stage: string): Promise<number[]> {
    let response = await fetch(`https://${process.env.REACT_APP_API_HOST}/flashcard/${stage}/count`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    let count = await response.json();
    console.log('Refreshing');
    return Array.from({ length: count.count }, (_, i) => i + 1);
  };
}

export default FlashCardRequestRemote;
