import ExplorerMapStage from './ExplorerMapStage';
import ExplorerMapData from './models/ExplorerMapData';

interface ExplorerMapProps {
  explorerMapData: ExplorerMapData[];
  levelsCompleted: string[];
}

// const determineNextFlashCardLevel = (stages: string[], stagesCompleted: string[]): string => {
//   const nextStage = stages.find(stage => !stagesCompleted.some(stageCompleted => stageCompleted === stage));
//   // default to the last stage if all stages are completed
//   return nextStage || stages[stages.length - 1]
// }

const ExplorerMap = (props: ExplorerMapProps) => {
  const stageLinks = props.explorerMapData.map((explorerMap, index) => {
    // const flashCardStageCompleted = flashCardStage.levels.every(level =>
    //   props.levelsCompleted.some(levelCompleted => level === levelCompleted));
    // const totalLevels = flashCardStage.levels.length;
    // if (flashCardStageCompleted) {
    //   const currentLevel = flashCardStage.levels[flashCardStage.levels.length - 1];
    //   return <FlashCardStage currentLevel={currentLevel} title={flashCardStage.title} imageUrl={flashCardStage.imageUrl}
    //     totalLevels={totalLevels} totalLevelsCompleted={totalLevels} />;
    // }

    // const currentLevel = determineNextFlashCardLevel(flashCardStage.levels, props.levelsCompleted);
    // const currentLevelIndex = flashCardStage.levels.findIndex(level => level === currentLevel);
    // const totalLevelsCompleted = (currentLevelIndex > 0) ? currentLevelIndex : 0;

    return <ExplorerMapStage key={index} explorerMapData={explorerMap} currentLevel={0} />;
  });

  return <div className="flex items-end justify-center gap-10 flex-wrap">{stageLinks}</div>;
};

export default ExplorerMap;
