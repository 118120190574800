import ExplorerMapData from "../components/ExplorerMap/models/ExplorerMapData";
import ExplorerMapRequest from "../components/ExplorerMap/services/ExplorerMapRequest";

export class ExplorerMapRequestLocal implements ExplorerMapRequest {
  async getAreas(): Promise<ExplorerMapData[]> {
    return [
      {
        imageUrl: 'https://image.freepik.com/free-vector/farmer-feeding-cow-with-grass_74855-10638.jpg',
        title: 'Farm',
        levels: ['farm-1', 'farm-2', 'farm-3']
      },
      {
        imageUrl: 'https://image.freepik.com/free-vector/group-wild-animals_1308-43813.jpg',
        title: 'Zoo',
        levels: ['zoo-1', 'zoo-2', 'zoo-3']
      },
      {
        imageUrl: 'https://image.freepik.com/free-vector/front-view-house-with-nature-elements-white-background_1308-55338.jpg',
        title: 'Around the house',
        levels: ['house-1', 'house-2', 'house-3']
      },
      
    ];
  }
}