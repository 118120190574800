import { FlashCardData } from './models/FlashCardData';
import FlashInput from '../flash_input';
import SoundTrigger from '../sound_trigger/sound_trigger';

interface IFlashCardProps {
  callbackNextCard: any;
  card: FlashCardData;
  stageCompleted: boolean;
}

export const FlashCard = (props: IFlashCardProps) => {
  const handleNextCard = async () => {
    props.callbackNextCard();
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col">
        <div className="flex justify-center pb-10">
          <SoundTrigger audioUrl={props.card.audioUrl} soundOnHover={true}>
            <div
              className="bg-center bg-cover bg-no-repeat inline-block xl:h-96 xl:w-96 h-44 w-44 lg:h-80 lg:w-80"
              style={{ backgroundImage: `url(${props.card.imageUrls[0]})` }}
            />
          </SoundTrigger>
        </div>
        <div className="flex justify-center pb-20">
          <FlashInput
            key={props.card.word}
            word={props.card.word}
            letters={props.card.letters}
            callbackNextCard={handleNextCard}
          />
        </div>
        {!props.stageCompleted ? (
          <div className="flex justify-center ">
            <button className="bg-blue-600 text-white p-3 w-full" name="Next" onClick={handleNextCard}>
              Next
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default FlashCard;
