import { ReactNode } from 'react';

/**
 * Will trigger an audio sound bite on click
 * and optional when hover
 */
interface SoundTriggerProp {
  children: ReactNode;
  audioUrl: string;
  soundOnHover: boolean;
}

const SoundTrigger = (props: SoundTriggerProp) => {
  const audio = props.audioUrl !== '' ? new Audio(props.audioUrl) : null;

  const startSound = () => {
    audio?.play();
  };

  const stopAndRestartSound = () => {
    if (audio !== null && audio !== undefined) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const onMouseEnter = () => {
    if (props.soundOnHover) {
      startSound();
    }
  };

  const onMouseOut = () => {
    if (props.soundOnHover) {
      stopAndRestartSound();
    }
  };

  return (
    <div className="inline-block cursor-pointer" onClick={startSound} onMouseEnter={onMouseEnter} onMouseOut={onMouseOut}>
      {props.children}
    </div>
  );
};

export default SoundTrigger;
