import { BrowserRouter, Route, Routes, Navigate, useParams } from 'react-router-dom';
import UserConfigContext, { defaultUserConfigContext } from './contexts/user_config_context';

import ExplorePage from './pages/ExplorePage';
import { FlashCardPage } from './pages/FlashCardPage/FlashCardPage';

import './App.css';

const AppPage = (props: any) => {
  const params = useParams();
  return (<FlashCardPage {...props} params={params} />);
};

export const EXPLORE_PATH = '/explore';

const App = () => {
  return (
    <UserConfigContext.Provider value={defaultUserConfigContext}>
      <BrowserRouter>
        {
          /* prettier-ignore */
          <Routes>
            <Route path="/" element={<Navigate to="/explore" />} />
            <Route path={EXPLORE_PATH} element={<ExplorePage />} />
            <Route path="/flash-card/:stage" element={<AppPage />} />
          </Routes>
        }
      </BrowserRouter>
    </UserConfigContext.Provider>
  );
};

export default App;
