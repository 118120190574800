import { useEffect, useState } from 'react';
import FlashCardStageCompletedDialog from '../../components/Dialogs/FlashCardStageCompletedDialog';
import FlashCard from '../../components/FlashCard';
import ApplicationLayout from '../../layouts/application_layout';
import IFlashCardData from '../../models/IFlashCardData';
import FlashCardRequestRemote from '../../services/FlashCardRequestRemote';
import FlashCardRequest from './services/FlashCardRequest';

export const FlashCardPage = (props: any) => {
  const [stageCompleted, setStageCompleted] = useState<boolean>(false);

  // List of flash card ids
  const [cardPool, setCardPool] = useState<number[]>([]);
  const [currentCard, setCurrentCard] = useState<IFlashCardData | null>(null);
  const [currentCardId, setCurrentCardId] = useState<number>(-1);

  // Flash Card Service
  const [flashCardRequest] = useState<FlashCardRequest>(new FlashCardRequestRemote());

  // Load once
  useEffect(() => {
    nextCard();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Redraw when flash card changes
  useEffect(() => {
    const loadCard = async () => {
      if (currentCardId > 0) {
        const card = await flashCardRequest.getCard(props.params.stage, currentCardId.toString());
        setCurrentCard(card);
      }
    };
    loadCard();
  }, [props.params.stage, currentCardId, flashCardRequest]);

  const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
  };

  const handleFlashCardCompleted = () => {
    const isStageCompleted = cardPool.length === 0;
    if (isStageCompleted) {
      setStageCompleted(true);
    } else {
      nextCard();
    }
  };

  const nextCard = async () => {
    let newCardPool = cardPool;
    if (newCardPool.length <= 0) {
      // Reset Card Pool if empty or first load
      newCardPool = await flashCardRequest.getDataRange(props.params.stage);
    }
    // Randomly pick a card in the pool, remove it, and show the card
    let cardToRemove = getRandomInt(newCardPool.length);
    setCardPool(newCardPool.filter((_, i) => i !== cardToRemove));
    setCurrentCardId(newCardPool[cardToRemove]);
  };

  return (
    <ApplicationLayout>
      {/* TODO - handle what happens if the API response throws an error and currentCard is not set */}
      {currentCard ? (
        // TODO review - displaying the completed dialog is complex and not so neat
        <div style={{ position: 'relative' }}>
          <FlashCard card={currentCard} callbackNextCard={handleFlashCardCompleted} stageCompleted={stageCompleted} />
          {stageCompleted && (
            <div style={{ position: 'absolute', bottom: '200px' }}>
              <FlashCardStageCompletedDialog />
            </div>
          )}
        </div>
      ) : (
        // TODO - need to decide how the application should handle loading state
        <span>Loading ...</span>
      )}
    </ApplicationLayout>
  );
};
